import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import sign from "../images/signup2.png";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default function Signup() {
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    geolocation: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const movetologin = () => {
    navigate("/Login");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("http://34.238.137.205:5000/api/creatuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: credentials.name,
        email: credentials.email,
        password: credentials.password,
        location: credentials.geolocation,
      }),
    });
    const json = await response.json();
    console.log(json);
    if (!json.success) {
      alert("Enter Valid Credentials");
    }
    if (json.success) {
      alert("Account Created Successfully");
      navigate("/Login");
    }
  };

  const onChange = (event) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  return (
    <>
      <div
        className="w-full h-screen"
        style={{
          backgroundImage: `url(${sign})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <div className="container w-full h-full flex items-center justify-center">
          <form
            onSubmit={handleSubmit}
            className="md:w-1/2 m-auto max-h-full p-4 border bg-white border-success rounded"
          >
            <h1 className="text-center">Sign Up</h1>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={credentials.name}
                onChange={onChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={credentials.email}
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={onChange}
              />
              <div id="emailHelp" className="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>
            <div className="mb-3 relative h-12">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control pr-10"
                name="password"
                value={credentials.password}
                id="exampleInputPassword1"
                onChange={onChange}
              />
              {/* Step 3: Toggle button for password visibility */}
              <button
                type="button"
                className="btn btn-outline-none absolute top-2/3 right-1 transform -translate-y-5/3 "
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <EyeSlash className="h-5 w-5" />
                ) : (
                  <Eye className="h-5 w-5" />
                )}
              </button>
            </div>
            <div className="mb-3 pt-3">
              <label htmlFor="exampleInputPassword2" className="form-label">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                name="geolocation"
                value={credentials.geolocation}
                id="exampleInputPassword2"
                onChange={onChange}
              />
            </div>
            <div className="p-auto">
              <button type="submit" className="rounded-full w-full bg-yellow-400 hover:bg-yellow-600 p-2 text-white">
                Submit
              </button>
              <p className="text-center mt-2">
                if you have already account{" "}
                <button type="submit" className="underline" onClick={movetologin}>
                  Login here
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
