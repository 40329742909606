import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom'


const RegistrationPopup = () => {
  const navigate = useNavigate();

const movetosign = () => {
  navigate('/creatuser');
};
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          {/* Your registration form content goes here */}
          <div className="bg-white p-4 rounded shadow-md">
            {/* Form elements and input fields */}
            <h2 className="text-xl font-bold mb-4">if you are new user Register yourself</h2>
            {/* Add your registration form fields here */}
            <div className="flex space-x-4">
          <button
           onClick={movetosign}
            className="px-4 py-2 bg-warning text-white rounded"
          >
            Register
          </button>
          <button
            onClick={handleClosePopup}
            className="px-4 py-2 bg-red-500 text-white rounded"
          >
            Close
          </button>
        </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationPopup;
