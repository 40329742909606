import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';

const CustomerCard = (props) => {
  return (
    <Card className="p-2 pt-4">
      <div className="pl-md-4 pt-2 bg-light">
        <p>
          <FontAwesomeIcon icon={faQuoteLeft} /> {props.description}<FontAwesomeIcon icon={faQuoteRight} />
        </p>
      </div>
      <div className="pl-md-4 d-flex align-items-center">
        <img src={props.image} className="h-12 w-12 rounded-full p-auto" alt="Product" />
        <h5 className="pl-3 pl-md-4 mb-2">{props.username}</h5>
      </div>
    </Card>
  );
}

export default CustomerCard;
