import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Button, CardImg } from "react-bootstrap";
import { useDispatchCart, useCart } from "./ContextReducer";

const ProCard = (props) => {
  const [qty, setqty] = useState(1);
  const priceRef = useRef();
  const [size, setsize] = useState("");
  let data = useCart();
  const [isLogging, setIsLogging] = useState(false);

  //const history = useHistory();
  const dispatch = useDispatchCart();
  // const isAuthenticated = true;
  const handleAddToCart = async () => {
    // let existingItem = data.find(item => item.id === props.fooditem._id && item.size === size);

    // if (existingItem) {
    //   console.log(existingItem.size)
    //   // Update quantity and price of existing item with the same size
    // dispatch({ type: "UPDATE", id: existingItem.id, price: finalPrice, qty: qty });
    // } else {
    // Add a new item to the cart
    await dispatch({
      type: "ADD",
      id: props.fooditem._id,
      name: props.fooditem.name,
      price: finalPrice,
      qty: qty,
      size: size,
      img: props.fooditem.img,
    });
    // }

    setIsLogging(true);
    // if (!isAuthenticated) {
    // Redirect to the login page if not authenticated
    //  history.push("/Login");
    //return; // Prevent further execution of the function
    // }
  };

  useEffect(() => {
    if (isLogging) {
      console.log(data);
      setIsLogging(false); // Reset isLogging after logging
    }
  }, [data, isLogging]);

  useEffect(() => {
    setsize(priceRef.current.value);
  }, []);

  // let data = useCart();
  // let dispatch = useDispatchCart();
  let options = props.options;
  let priceOptions = Object.keys(options);
  // let sortedOptions = priceOptions.sort();
  let finalPrice = qty * parseInt(options[size]);
  // const priceRef = useRef();

  // useEffect(() => {
  //   setsize(priceRef.current.value);
  // }, []);

  // let { foodname,imgsrc }
  //  let options =props.options;
  //  let priceOptions= Object.keys(options);
  return (
    <>
        <div className="w-full border border-gray-500 rounded-xl">
          <div className="w-full flex flex-col items-center justify-center gap-2">
            <div>
              <img
                src={props.fooditem.img}
                className="w-full h-[250px]"
                alt={props.fooditem.name}
              />
            </div>
            <div>
              <h1 className="text-xl font-bold">{props.fooditem.name}</h1>
            </div>
            <div className="flex justify-evenly items-centers w-full">
              <select
                className="p-2 bg-warning rounded"
                onChange={(e) => setqty(e.target.value)}
              >
                {Array.from(Array(6), (e, i) => {
                  return <option key={i + 1}>{i + 1}</option>;
                })}
              </select>
              <select
                className="p-2 bg-warning rounded"
                ref={priceRef}
                onChange={(e) => setsize(e.target.value)}
              >
                {priceOptions.map((data) => {
                  return (
                    <>
                      <option key={data} value={data}>
                        {data}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
            <div>
              <p className="font-bold text-center text-xl">{finalPrice} Rs</p>
            </div>
            <button
              className="w-full p-2 bg-yellow-400 text-white hover:bg-yellow-600 rounded-none"
              variant="warning"
              onClick={handleAddToCart}
            >
              Add to Cart
            </button>
          </div>
        </div>
    </>
  );
};

export default ProCard;
