import React from "react";
import {
  Facebook,
  Twitter,
  Instagram,
  Envelope,
  Youtube,
  Pinterest,
  Telephone,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="bg-gray-600 w-full p-2 flex flex-col items-center justify-center">
      <div className="flex items-center justify-center w-full">
        <img
          src="/images/logo.png"
          className="w-1/2 md:w-1/5"
          alt="Logo"
        />
      </div>
      <hr className="border border-gray-400 w-3/4" />
      <div className="flex items-center justify-center text-white text-2xl gap-4">
        <Link to=" https://www.instagram.com/kojiglow/?igshid=MzRlODBiNWFlZA%3D%3D" className="text-white">
          <Instagram className="hover:text-purple-500" />
        </Link>
        <Link to="https://www.facebook.com/profile.php?id=100089619647369&mibextid=ZbWKwL" className="text-white">
          <Facebook className="hover:text-blue-500" />
        </Link>
        <Link to="https://www.pinterest.com/kojiglow/?invite_code=ae9166e2e45d42d0a7c20b378e2622de&sender=904942256283325366" className="text-white">
          <Pinterest className="hover:text-pink-500" />
        </Link>
        <Link to="https://www.youtube.com/@kojiglow" className="text-white">
          <Youtube className="hover:text-red-500" />
        </Link>
        <Link to="" className="text-white">
          <Twitter className="hover:text-blue-500" />
        </Link>
        <Link to="" className="text-white">
          <Envelope className="hover:text-white" />
        </Link>
      </div>
      <div>
        <Link
          to="tel:0333 4374434"
          className="text-white flex items-center gap-2 mt-4 no-underline"
        >
          <span>
            <Telephone className="text-xl" />
          </span>
          <span>+92 333 4374434</span>
        </Link>
      </div>
      <div className="container text-white text-center mt-4">
        <p className="copyright_text"> © 2023 All Rights Reserved.</p>{" "}
       <Link to="http://www.flowtechnologies.com.pk/"> © powered by Flow Tecchnlogies.</Link> 
      </div>
    </div>
  );
};

export default Footer;
