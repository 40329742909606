import React from "react";
import { Link } from "react-scroll";

export default function Banner() {
  // Navbar data
  const data = [
    { title: "New Release", action: () => alert("New Release - Coming Soon") },
    { title: "Blogs", action: () => alert("Blogs - Coming Soon") },
    { title: "Koji Live", moveto: "Live" },
    { title: "Customer Reviews", moveto: "Reviews" },
    { title: "Contact Us", moveto: "Footer" },
  ];

  return (
    <>
      <div className="m-auto w-full md:w-11/12 h-auto flex justify-between items-center px-4 bg-gray-700 text-white md:p-4 cuttheside">
        <ul className="m-auto w-100 text-center grid grid-cols-2 md:flex justify-evenly items-center p-2 gap-2">
          {data.map((navdata) => (
            <li key={navdata.title}>
              {navdata.action ? (
                <span
                  className="hover:text-orange-400 uppercase"
                  onClick={navdata.action}
                  style={{ cursor: "pointer" }}
                >
                  {navdata.title}
                </span>
              ) : (
                <Link to={navdata.moveto}>
                  <span className="hover:text-orange-400 uppercase">
                    {navdata.title}
                  </span>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4">
        <Link to="/">
          <img className="top-2 m-auto h-20 hidden md:block" src="/images/logo.png" alt="Logo" />
        </Link>
      </div>
    </>
  );
}
