import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CustomerCard from "./CustomerCard";
import { Autoplay, Pagination, Navigation } from "swiper";

const Customercarsoule = () => {

  const data = [
    {
      name : "Lumona Melungwa",
      description : " Great job, and excellent service. You are listed in my favorites list of web sites as number one. Thank you again. ",
      image : "https://m.media-amazon.com/images/M/MV5BMTYwMTI5NTM2OF5BMl5BanBnXkFtZTcwODk3MDQ2Mg@@._V1_FMjpg_UX1000_.jpg"

    },
    {
      name : "Alex",
      description : " Great job, and excellent service. You are listed in my favorites list of web sites as number one. Thank you again. ",
      image : "https://media.wired.com/photos/655cf58564de22446963f396/master/pass/Sam-Altman-OpenAI-Return-Business-1258197415.jpg"

    },
    {
      name : "Jack",
      description : " Great job, and excellent service. You are listed in my favorites list of web sites as number one. Thank you again. ",
      image : "https://images.freeimages.com/images/large-previews/36d/brasilian-white-man-6-1241264.jpg?fmt=webp&w=350"
    },
    {
      name : "Jack",
      description : " Great job, and excellent service. You are listed in my favorites list of web sites as number one. Thank you again. ",
      image : "https://images.freeimages.com/images/large-previews/36d/brasilian-white-man-6-1241264.jpg?fmt=webp&w=350"
    },
    {
      name : "Lumona Melungwa",
      description : " Great job, and excellent service. You are listed in my favorites list of web sites as number one. Thank you again. ",
      image : "https://m.media-amazon.com/images/M/MV5BMTYwMTI5NTM2OF5BMl5BanBnXkFtZTcwODk3MDQ2Mg@@._V1_FMjpg_UX1000_.jpg"

    },
    {
      name : "Alex",
      description : " Great job, and excellent service. You are listed in my favorites list of web sites as number one. Thank you again. ",
      image : "https://media.wired.com/photos/655cf58564de22446963f396/master/pass/Sam-Altman-OpenAI-Return-Business-1258197415.jpg"

    },
  ]
  return (
    <div className="w-full">
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper mt-4 sm:w-5/6 md:w-4/5 lg:w-3/4 xl:w-2/3 mx-auto "
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >{data.map(users=>(
        <SwiperSlide>
          <CustomerCard username = {users.name} description = {users.description} image={users.image} />
        </SwiperSlide>
        ))
      }
      </Swiper>
    </div>
  );
};

export default Customercarsoule;
