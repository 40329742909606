import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import sign from "../images/signup2.png";

export default function AdminSignup() {
  const [credentials, setCredentials] = useState({ name: "", email: "", password: ""});

  const navigate = useNavigate();
  const movetologin = () => {
    navigate("/Admin");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("http://34.238.137.205:5000/api/creatadmin", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: credentials.name, email: credentials.email, password: credentials.password,  })
    });
    const json = await response.json();
    console.log(json);
    if (!json.success) {
      alert("Enter Valid Credentials");
    }
    if (json.success) {
      alert("Account Created Successfully");
    }
  };

  const onChange = (event) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  return (
    <>
      <div className="w-full h-screen" style={{
        backgroundImage: `url(${sign})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%"
      }}>
        <div className='container h-full p-8'>
       
          <form onSubmit={handleSubmit} className='w-3/4 md:w-1/2 m-auto  p-4 border bg-white border-success rounded'>
          <h1 className='text-center'>Admin Sign up</h1>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" className="form-control" name='name' value={credentials.name} onChange={onChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
              <input type="email" className="form-control" name='email' value={credentials.email} id="exampleInputEmail1" aria-describedby="emailHelp" onChange={onChange} />
              <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
              <input type="password" className="form-control" name='password' value={credentials.password} id="exampleInputPassword1" onChange={onChange} />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="exampleInputPassword2" className="form-label">Address</label>
              <input type="text" className="form-control" name='geolocation' value={credentials.geolocation} id="exampleInputPassword2" onChange={onChange} />
            </div> */}
            <button type="submit" className="bg-yellow-400 hover:bg-yellow-600 p-2 text-white rounded-full w-full" onClick={movetologin}>Submit</button>
            <p className='p-2'>if you have already account <button type="submit" className="underline" onClick={movetologin}>Login here</button></p>
          </form>
        </div>
      </div>
    </>
  )
}
