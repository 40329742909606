import React from "react";
import Carts from "./Carts";
import { Shield } from "react-bootstrap-icons";


import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { List, Search, Cart, Person, X } from "react-bootstrap-icons";
import Badge from "react-bootstrap/Badge";
import { BsBagCheck, BsFillPersonPlusFill } from "react-icons/bs";

import { IoIosLogOut } from "react-icons/io";

import { useNavigate } from "react-router-dom";

import bg from "../images/bg8.png";
import Kojilive from "../components/Kojilive";

import Banner from "../components/Banner";
// import Navbar from "../components/Navbar.js/Navbar";

import "uikit/dist/css/uikit.min.css";
import Carasoule from "../components/Carasoule";
// import Carousel from "../components/ProdCard";

// import Productslide from "../components/Productslide";
import ProCard from "../components/ProdCard";
// import Productslide2 from "../components/Productslide2";
import Footer from "../components/Footer";
import { useCart } from "../components/ContextReducer";

import { useState, useEffect } from "react";

import Customercarsoule from "../components/Customercarsoule";
import Modal from "../Modal";
// import ElfSightWidget from "../components/RegistrationPopup";
import RegistrationPopup from "../components/RegistrationPopup";
export default function Home() {
  const [cartView, setCartView] = useState(false);
  const [search, setsearch] = useState("");
  const [foodcat, setfoodcat] = useState([]);
  const [fooditem, setfooditem] = useState([]);
  const navigate = useNavigate();
  const navigate2 = useNavigate();
  const [liststate, setListState] = useState("block");
  const [crossstate, setCrossState] = useState("hidden");
  const [remove, setRemove] = useState("block");

  let data = useCart();
  const movetomyorder = () => {
    navigate("/myorder");
  };

  const movetosignup = () => {
    navigate("/creatuser");
  };
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate2("/Login");
  };

  const hamburger = () => {
    setCrossState("block");
    setListState("hidden");
    setRemove("hidden");
  };

  const closebar = () => {
    setCrossState("hidden");
    setListState("block");
    setRemove("block");
  };

  const loadData = async () => {
    let response = await fetch("http://34.238.137.205:5000/api/foodData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    response = await response.json();
    setfoodcat(response.foodCategory);
    setfooditem(response.food_items);

    //console.log(response.food_items, response.foodCategory);
  };
  useEffect(() => {
    loadData();

    // Cleanup function
    return () => {
      // Perform any necessary cleanup here
    };
  }, []);

  const navigate3 = useNavigate();
  const movetologin = () => {
    navigate3("/Login");
  };
  const moveToAdminPanel = () => {
    navigate("/Admin");
  };
  

  return (
    <>
      <div
        className="w-full h-screen"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <div className="hidden md:block">
          <Banner />
        </div>
        <div className="w-11/12 mx-auto hidden md:block">
          <ul className="flex justify-evenly items-center text-white">
            <li>
              <select className="p-2 rounded bg-gray-800">
                <option selected disabled>
                  All Category
                </option>
                <option>Soap</option>
                <option>Serums</option>
                <option>Any thing else</option>
              </select>
            </li>
            <li>
              <div className="flex items-center">
                <input
                  type="search"
                  className="p-2 rounded-tl rounded-bl w-11/12 placeholder:text-black text-black"
                  placeholder="Search the item"
                  value={search}
                  onChange={(e) => {
                    setsearch(e.target.value);
                  }}
                />
                <button className="p-2.5 rounded-tr rounded-br text-xl text-white bg-orange-500">
                  <Search />
                </button>
              </div>
            </li>
            <li>
              {localStorage.getItem("authToken") ? (
                <div className="flex items-center hover:text-orange-500">
                  <BsBagCheck
                    className="text-2xl bold cursor-pointer"
                    onClick={movetomyorder}
                  />
                  <p
                    className="p-2 text-1xl pt-4 cursor-pointer"
                    onClick={movetomyorder}
                  >
                    My Orders
                  </p>
                </div>
              ) : (
                ""
              )}
            </li>
            <li className="   ">
              {!localStorage.getItem("authToken") ? (
                <div className="flex items-center hover:text-orange-500">
                  <BsFillPersonPlusFill
                    className="text-2xl bold cursor-pointer "
                    onClick={movetosignup}
                  />
                  <p
                    className="p-2 text-1xl cursor-pointer pt-4"
                    onClick={movetosignup}
                  >
                    Sign up
                  </p>
                </div>
              ) : (
                <li>
                  <div className="flex items-center hover:text-orange-500">
                    <Badge pill bg="danger">
                      {data.length}
                    </Badge>
                    <Cart
                      className="text-2xl bold cursor-pointer "
                      onClick={() => {
                        setCartView(true);
                      }}
                    />
                    <p
                      className="p-2 text-1xl cursor-pointer pt-4"
                      onClick={() => {
                        setCartView(true);
                      }}
                    >
                      Cart{" "}
                    </p>
                  </div>
                  {cartView ? (
                    <Modal
                      onClose={() => {
                        setCartView(false);
                      }}
                    >
                      {" "}
                      <Carts />
                    </Modal>
                  ) : null}
                </li>
              )}
            </li>
            <li>
              {!localStorage.getItem("authToken") ? (
                <div
                  className="flex items-center hover:text-orange-500"
                  id="change"
                >
                  <Person
                    className="text-2xl bold cursor-pointer "
                    onClick={movetologin}
                  />
                  <p
                    className="p-2 text-1xl cursor-pointer pt-4"
                    onClick={movetologin}
                  >
                    Login
                  </p>
                </div>
              ) : (
                <li>
                  <div className="flex items-center hover:text-orange-500">
                    <IoIosLogOut
                      className="text-2xl bold cursor-pointer "
                      onClick={handleLogout}
                    />
                    <p
                      className="p-2 text-1xl cursor-pointer pt-4"
                      onClick={handleLogout}
                    >
                      Logout
                    </p>
                  </div>
                </li>
              )}
            </li>
           
            {!localStorage.getItem("authToken") && (
      <li>
        <div
          className="flex items-center hover:text-orange-500"
          onClick={moveToAdminPanel}
        >
          <Shield className="text-2xl bold cursor-pointer" />
          <p className="p-2 text-1xl cursor-pointer pt-4">Admin</p>
        </div>
      </li>
    )}
    
          </ul>
        </div>
        {/* Mobile screen Navbar */}
        <div className={"md:hidden h-auto w-full " + remove}>
          <ul className="text-white w-full mx-auto flex items-center justify-between p-2 bg-gray-700">
            <li className="">
              <List className={"text-xl " + liststate} onClick={hamburger} />
              <X className={"text-xl " + crossstate} onClick={closebar} />
            </li>
            {!localStorage.getItem("authToken") ? (
              <li id="change">
                <div
                  className="cursor-pointer flex justify-center items-center"
                  onClick={movetologin}
                >
                  <span>
                    <Person className="text-lg bold " onClick={movetologin} />
                  </span>
                  <span>Login</span>
                </div>
              </li>
            ) : (
              <li>
                <div
                  className="cursor-pointer flex items-center justify-center h-full"
                  onClick={handleLogout}
                >
                  <span>
                    <IoIosLogOut
                      className="bold text-lg"
                      onClick={handleLogout}
                    />
                  </span>
                  <span>Logout</span>
                </div>
              </li>
            )}
          </ul>
        </div>
        {/* Menu  */}
        <div
          className={
            "bg-gray-700 w-full h-screen fixed mt-0 z-10 md:hidden " +
            crossstate
          }
        >
          <ul className="text-white w-full mx-auto flex items-center justify-between p-2 bg-gray-700">
            <li className="">
              <X className={"text-xl " + crossstate} onClick={closebar} />
            </li>
            {!localStorage.getItem("authToken") ? (
              <li id="change">
                <div
                  className="cursor-pointer flex justify-center items-center"
                  onClick={movetologin}
                >
                  <span>
                    <Person className="text-lg bold " onClick={movetologin} />
                  </span>
                  <span>Login</span>
                </div>
              </li>
            ) : (
              <li>
                <div
                  className="cursor-pointer flex items-center justify-center h-full"
                  onClick={handleLogout}
                >
                  <span>
                    <IoIosLogOut
                      className="bold text-lg"
                      onClick={handleLogout}
                    />
                  </span>
                  <span>Logout</span>
                </div>
              </li>
            )}
          </ul>

          <div className="flex flex-col w-full">
            <div className="flex items-center w-11/12 mx-auto">
              <input
                type="search"
                className="p-2 rounded-tl rounded-bl w-11/12 placeholder:text-black text-black"
                placeholder="Search the item"
                value={search}
                onChange={(e) => {
                  setsearch(e.target.value);
                }}
              />
              <button className="p-2.5 rounded-tr rounded-br text-xl text-white bg-orange-500">
                <Search />
              </button>
            </div>
          </div>
          <div className="w-11/12 mx-auto flex justify-between items-center">
            <div className="text-white">
              {!localStorage.getItem("authToken") ? (
                <div className="flex items-center hover:text-orange-500">
                  <BsFillPersonPlusFill
                    className="text-2xl bold cursor-pointer "
                    onClick={movetosignup}
                  />
                  <p
                    className="p-2 text-1xl cursor-pointer pt-4"
                    onClick={movetosignup}
                  >
                    Sign up
                  </p>
                </div>
              ) : (
                <div>
                  <div className="flex items-center hover:text-orange-500">
                    <Badge pill bg="danger">
                      {data.length}
                    </Badge>
                    <Cart
                      className="text-2xl bold cursor-pointer "
                      onClick={() => {
                        setCartView(true);
                      }}
                    />
                    <p
                      className="p-2 text-1xl cursor-pointer pt-4"
                      onClick={() => {
                        setCartView(true);
                      }}
                    >
                      Cart{" "}
                    </p>
                  </div>
                  {cartView ? (
                    <Modal
                      onClose={() => {
                        setCartView(false);
                      }}
                    >
                      {" "}
                      <Carts />
                    </Modal>
                  ) : null}
                </div>
              )}
            </div>
            <div>
              {localStorage.getItem("authToken") ? (
                <div className="flex items-center text-white hover:text-orange-500">
                  <BsBagCheck
                    className="text-2xl bold cursor-pointer"
                    onClick={movetomyorder}
                  />
                  <p
                    className="p-2 text-1xl pt-4 cursor-pointer"
                    onClick={movetomyorder}
                  >
                    My Orders
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            
          </div>
          <div className="block md:hidden">
            <Banner/>
          </div>
        </div>

        <div>
          <Carasoule />
        </div>

        <div className="container">
          {foodcat.length !== 0
            ? foodcat.map((data) => {
                return (
                  <>
                    <div key={data._id} className="">
                      <h1 className="mt-24"> {data.CategoryName}</h1>
                    </div>
                    <hr />
                    {/* tablet to Desktop size  */}
                    <div className="w-full hidden md:block">

                    <Swiper
                      slidesPerView={3}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      className=""
                     >
                      {fooditem.length !== 0 ? (
                        fooditem
                          .filter(
                            (item) =>
                              item.CategoryName === data.CategoryName &&
                              item.name
                                .toLowerCase()
                                .includes(search.toLocaleLowerCase())
                          )
                          .map((filteritems) => {
                            return (
                              <>
                                <SwiperSlide className="shadow-2xl rounded-xl w-full">
                                  <ProCard
                                    fooditem={filteritems}
                                    key={filteritems._id}
                                    options={filteritems.options[0]}
                                  />
                                </SwiperSlide>
                              </>
                            );
                          })
                      ) : (
                        <div>No Such Data Found</div>
                      )}{" "}
                    </Swiper>
                    </div>

                  {/* Mobile Screen size  */}
                  <div 
                      className="w-full md:hidden">
                  <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                     >
                      {fooditem.length !== 0 ? (
                        fooditem
                          .filter(
                            (item) =>
                              item.CategoryName === data.CategoryName &&
                              item.name
                                .toLowerCase()
                                .includes(search.toLocaleLowerCase())
                          )
                          .map((filteritems) => {
                            return (
                              <>
                                <SwiperSlide className="shadow-2xl rounded-xl w-full">
                                  <ProCard
                                    fooditem={filteritems}
                                    key={filteritems._id}
                                    options={filteritems.options[0]}
                                  />
                                </SwiperSlide>
                              </>
                            );
                          })
                      ) : (
                        <div>No Such Data Found</div>
                      )}{" "}
                    </Swiper>
                  </div>

                  </>
                );
              })
            : null}
        </div>
        <div>
          <RegistrationPopup />
        </div>
        <div className="p-2 pl-36 " id="Reviews">
          <h1 className=" pl-12 font-mono font-black mt-5  text-black">
            Customer Reviews
          </h1>
          <hr className=" ml-10 mr-10 pl-12 my-2  border-gray-800" />
        </div>

        <div className="pb-4">
          <Customercarsoule />
        </div>
        <div className="p-2 pl-36 " id="Live">
          <h1 className=" pl-12 font-mono font-black mt-5  text-black">
            Koji Live
          </h1>
          <hr className=" ml-10 mr-10 pl-12 my-2  border-gray-800" />
        </div>
        <div>
          <Kojilive />
        </div>

        {/* <div className="flex items-center justify-center">
          <h1 className="font-mono font-black mt-20 text-black p-4">Soaps</h1>
        </div>
        <div>
          <Productslide2 />
        </div>
        <div className="text-center">
          <h1 className="font-mono font-black mt-6 text-black p-2">Serums</h1>
        </div>
        <div>
          <Productslide />
        </div> */}
        {/* <div className=" flex items-center justify-center">
          <h1 className="font-mono font-black mt-18 text-black p-2">Customer Reviews</h1>
        </div>
         */}
        <div id="Footer">
          <Footer />
        </div>
      </div>
    </>
  );
}
