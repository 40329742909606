import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import sign from "../images/signup2.png";


export default function AdminLogin() {
  const [credentials, setcredentials] = useState({ email: '', password: '' });

  const navigate = useNavigate();

  const movetosign = () => {
    navigate('/creatuser');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = credentials; // Destructure email and password from the credentials object
  
    // Send a request to the backend to check admin credentials
    try {
      const response = await fetch('http://34.238.137.205:5000/api/admin-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const json = await response.json();
  
      if (json.success) {
        // Admin credentials are valid, navigate to the admin dashboard
       
        navigate('/dashboard');
        //alert("you are Admin")
       
      } else {
        // Admin credentials are invalid, show an alert
        alert('You are not an Admin.');
      }
    } catch (error) {
      // Handle error if there's an issue with the request
      console.error('Error checking admin credentials:', error);
      alert('An error occurred. Please try again later.');
    }
  };
  

  const onChange = (event) => {
    setcredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  return (
    <>
    <div className="w-full h-screen" style={{
        backgroundImage: `url(${sign})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%"
      }}>
      <div className='container h-full p-8'>
        <form onSubmit={handleSubmit}className='w-3/4 md:w-1/2 m-auto p-4 border bg-white border-success rounded' >
          <div className='mb-3'>
          <h1 className='text-center'>Admin Sign In</h1>
            <label htmlFor='exampleInputEmail1' className='form-label'>
              Email address
            </label>
            <input
              type='email'
              className='form-control'
              name='email'
              value={credentials.email}
              id='exampleInputEmail1'
              aria-describedby='emailHelp'
              onChange={onChange}
            />
            <div id='emailHelp' className='form-text'>
              We'll never share your email with anyone else.
            </div>
          </div>
          <div className='mb-3'>
            <label htmlFor='exampleInputPassword1' className='form-label'>
              Password
            </label>
            <input
              type='password'
              className='form-control'
              name='password'
              value={credentials.password}
              id='exampleInputPassword1'
              onChange={onChange}
            />
          </div>

          <button type='submit' className='bg-yellow-400 hover:bg-yellow-600 p-2 text-white rounded-full w-full'>
            Submit
          </button>
          {/* <p className='p-2'>
            If you don't have an account,{' '}
            <button type='submit' className='underline' onClick={movetosign}>
              Sign up here
            </button>
          </p> */}
        </form>
      </div>
      </div>
    </>
  );
}
