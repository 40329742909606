import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import sign from "../images/signup2.png";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default function Login() {
  const [credentials, setcredentials] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const movetosign = () => {
    navigate("/creatuser");
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("http://34.238.137.205:5000/api/loginuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: credentials.email,
        password: credentials.password,
      }),
    });
    const json = await response.json();
    console.log(json);

    if (!json.success) {
      alert("Enter Valid Credentials");
    } else {
      // Set email and authentication token in localStorage
      localStorage.setItem("userEmail", credentials.email);
      localStorage.setItem("authToken", json.authToken);

      // Navigate to home page
      navigate("/");
    }
  };

  const onChange = (event) => {
    setcredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  return (
    <>
      <div
        className="w-full h-screen"
        style={{
          backgroundImage: `url(${sign})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <div className="container h-full w-full flex items-center justify-center">
          <form
            onSubmit={handleSubmit}
            className="md:w-1/2 m-auto h-auto  p-4 border bg-white border-success rounded"
          >
            <h1 className="text-center">Sign In</h1>
            <div className="mb-3 p-auto">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={credentials.email}
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={onChange}
              />
              <div id="emailHelp" className="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>
            <div className="mb-3 relative h-16 pt-2">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control pr-10"
                name="password"
                value={credentials.password}
                id="exampleInputPassword1"
                onChange={onChange}
              />
              {/* Step 3: Toggle button for password visibility */}
              <button
                type="button"
                className="btn btn-outline-none absolute top-2/3 right-1 transform -translate-y-5/3 "
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <EyeSlash className="h-5 w-5" />
                ) : (
                  <Eye className="h-5 w-5" />
                )}
              </button>
            </div>
            <div className="mt-4">
              <button type="submit" className="p-2 bg-yellow-400 w-full rounded-full text-white">
                Submit
              </button>
            </div>
            <p className="pt-2 text-center">
              If you don't have an account,{" "}
              <button type="submit" className="underline" onClick={movetosign}>
                Sign up here
              </button>
            </p>
          </form>
        </div>
      </div>
    </>
  );
}
