// import React, { useState, useEffect } from 'react';

// ... other imports ...
import React, { useState, useEffect } from "react";
import { BsTrash } from "react-icons/bs";
import OrderButton from "../components/OrderButton";

const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  //const [delivered, setDelivered] = useState(false);

  useEffect(() => {
    // Fetch all orders when the component mounts
    fetchAllOrders();
  }, []);

  const fetchAllOrders = async () => {
    try {
      const response = await fetch("http://34.238.137.205:5000/api/orders");
      if (response.ok) {
        const data = await response.json();
        const ordersWithButtonText = data.map((order) => ({
          ...order,
          buttonText: "Pending",
        }));
        setOrders(ordersWithButtonText);
        // setOrders(data);
      } else {
        console.log("Error fetching orders");
      }
    } catch (error) {
      console.log("Error fetching orders:", error.message);
    }
  };
  const handleDeleteOrder = async (orderId) => {
    try {
      const response = await fetch(
        `http://34.238.137.205:5000/api/orders/${orderId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        // If the order was deleted successfully, fetch all orders again to update the list
        fetchAllOrders();
      } else {
        console.log("Error deleting order");
      }
    } catch (error) {
      console.log("Error deleting order:", error.message);
    }
  };

  return (
    <>
      <div className="w-full">
        <div>
          <h1 className="text-center bg-yellow-500 uppercase font-sans text-light p-2">
            DashBoard
          </h1>
        </div>

        {/* Display orders */}
        {orders.map((order) => {
          console.log("Order:", order); // Debugging line to check if orders are present in the array
          const orderRows = order.order_data.flat().map((item, index) => (
            <tr key={index}>
              <td>{item.Order_date}</td>
              <td>{item.name}</td>
              <td>{item.qty}</td>
              <td>{item.size}</td>
              <td>{item.price}</td>

              <td>
                {" "}
                <OrderButton initialText={order.buttonText} />
              </td>
            </tr>
          ));
          return (
            
            <React.Fragment key={order._id}>
            
              <table className="table w-full">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Order ID</th>
                    <th>Delete Order</th>

                    {/* Add other order details as needed */}
                  </tr>
                  <tr>
                    <td>{order.email}</td>
                    <td>{order.name}</td>
                    <td>{order.location}</td>
                    <td>{order._id}</td>
                    <td className="cursor-pointer">
                      <BsTrash onClick={() => handleDeleteOrder(order._id)} />{" "}
                    </td>
                    {/* Add other order details as needed */}
                  </tr>
                  <tr>
                    <th>Date</th>
                    <th>Item</th>
                    <th>Qty</th>
                    <th>Size</th>
                    <th>Price</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>{orderRows}</tbody>
              </table>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default AllOrders;
