import "./App.css";
//import '../node_modules/bootstrap-dark-5/dist/css/bootstrap-dark.min.css'  //npm i bootstrap-dark-5 boostrap
//import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
//import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import Home from "./screens/Home";
import Login from "./screens/Login";
import AdminSignup from "./screens/AdminSignup";
// import Admin from '../backend/models/Admin';
import React, { createContext, useContext, useReducer } from "react";
import { Routes, Route } from "react-router-dom";
import Signup from "./screens/Signup";
import { CartProvider } from "./components/ContextReducer";
import Cart from "./screens/Carts";
import MyOrder from "./screens/MyOrder";
import AdminLogin from "./screens/AdminLogin";
import Dashboard from "./screens/Dashboard";

// import '../node_modules/bootstrap-dark-5/dist/css/bootstrap-dark.min.css';
// import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
// import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
    <div className="body">
      <CartProvider>
        <Routes>
          <Route exect path="/" element={<Home />} />
          <Route exect path="/Login" element={<Login />} />
          <Route exect path="/creatuser" element={<Signup />} />
          <Route exect path="/cart" element={<Cart />} />
          <Route exect path="/myorder" element={<MyOrder />} />
          <Route exect path="/creatadmin" element={<AdminSignup />} />
          <Route exect path="/Admin" element={<AdminLogin />} />
          <Route exect path="/dashboard" element={<Dashboard />} />
        </Routes>
      </CartProvider>
    </div>
  );
}

export default App;
