import React, { useState } from "react";
import { BsTrash } from "react-icons/bs";
import { useCart, useDispatchCart } from "../components/ContextReducer";

const Carts = () => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");

  const data = useCart();
  const dispatch = useDispatchCart();

  if (data.length === 0) {
    return (
      <div>
        <div className="m-4 w-full text-center fs-2">The Cart is Empty!</div>
      </div>
    );
  }
  const orderName = name; // The fetched name from state
  const orderLocation = location;

  const handleCheckOut = async () => {
    let userEmail = localStorage.getItem("userEmail");

    let response = await fetch("http://34.238.137.205:5000/api/orderData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order_data: data,
        email: userEmail,
        order_date: new Date().toDateString(),
        name: orderName, // Include the fetched name in the request body
        location: orderLocation,
      }),
    });
    console.log("Current name:", orderName);
    console.log("Current location:", orderLocation);
    console.log("JSON RESPONSE:::::", response.status);
    if (response.status === 200) {
      dispatch({ type: "DROP" });
    }
    alert("Products will dilvered in 2 days ThankYou! ");
  };

  let totalPrice = data.reduce((total, food) => total + food.price, 0);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Fetch user's name and location
    let userEmail = localStorage.getItem("userEmail");
    let response = await fetch("http://34.238.137.205:5000/api/getuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: userEmail }),
    });

    if (response.ok) {
      const data = await response.json();
      setName(data.name);
      setLocation(data.location);
    }
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  return (
    <>
      {/* {console.log(data)} */}
      <div className="flex flex-col w-full">
        <table className="table ">
          <thead className=" text-success">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Quantity</th>
              <th scope="col">Option</th>
              <th scope="col">Amount</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((food, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{food.name}</td>
                <td>{food.qty}</td>
                <td>{food.size}</td>
                <td>{food.price}</td>
                <td>
                  <button type="button" className="btn p-0">
                    <BsTrash
                      onClick={() => {
                        dispatch({ type: "REMOVE", index: index });
                      }}
                    />
                  </button>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* {totalPrice} */}
        <div className="flex flex-col md:flex-row w-full items-center justify-evenly">
          <div className="flex justify-end">
            <div className="h-full p-4 pb-2">
              <h1 className="">Confirmation/Change</h1>
              <form
                className="w-full h-auto m-auto bg-white  rounded"
                onSubmit={handleSubmit}
              >
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="location"
                    value={name} // Update value with fetched name
                    //readOnly
                    onChange={handleNameChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Address:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    value={location} // Update value with fetched location
                    //readOnly
                    onChange={handleLocationChange}
                  />
                </div>
                <div className="flex justify-start  pt-3">
                  <p className="pr-4">Click here to See</p>
                  <button
                    type="submit"
                    className="btn btn-warning "
                    onClick={handleSubmit}
                  >
                    See
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="flex flex-col">
            <div>
              <h1 className="fs-2">Total Price:{totalPrice} /-</h1>
            </div>
            <div>
              {/* onClick={handleCheckOut} */}
              <button className="p-2 bg-yellow-400 hover:bg-yellow-600 w-full md:w-auto rounded-full md:rounded text-white" onClick={handleCheckOut}>
                {" "}
                Check Out{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Carts;
