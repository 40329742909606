import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
// 34.238.137.205

export default function MyOrder() {
  const [orderData, setOrderData] = useState({});

  const fetchMyOrder = async () => {
    try {
      console.log(localStorage.getItem("userEmail"));
      const response = await fetch("http://34.238.137.205:5000/api/myOrderData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: localStorage.getItem("userEmail"),
        }),
      });
      const data = await response.json();
      console.log(data);
      setOrderData(data.orderData ?? {});
    } catch (error) {
      console.error("Error fetching order data:", error.message);
    }
  };

  useEffect(() => {
    fetchMyOrder();
  }, []);

  return (
    <div>
      <div className="w-11/12 mx-auto">
        <h1 className=" uppercase font-light mt-4  text-black">My Orders</h1>
        <hr className=" p-2 w-full mx-auto border-gray-800" />
      </div>
      <div className="w-11/12 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
          {orderData?.order_data?.length > 0 ? (
            orderData.order_data
              .slice(0)
              .reverse()
              .map((orderItems, orderIndex) => (
                <div key={orderIndex}>
                  {orderItems[0]?.Order_date && (
                    <div className="m-auto">
                      {orderItems[0].Order_date}
                    </div>
                  )}

                  <div className="row">
                    {orderItems
                      .filter((_, itemIndex) => itemIndex !== 0) // Skip the first element
                      .map((itemData, itemIndex) => (
                        <div key={itemIndex} className="">
                          <div
                            className="card mt-3"
                          >
                            <img
                              src={itemData.img}
                              className="card-img-top"
                              alt="..."
                              style={{ height: "150px", objectFit: "fill" }}
                            />
                            <div className="card-body">
                              <h5 className="card-title">{itemData.name}</h5>
                              <div
                                className="container w-100 p-0"
                                style={{ height: "38px" }}
                              >
                                <span className="m-1">{itemData.qty}</span>
                                <span className="m-1">{itemData.size}</span>
                                <div className="d-inline ms-2 h-100 w-20 fs-5">
                                  Rs{itemData.price}/-
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <hr />
                </div>
              ))
          ) : (
            <p>No orders yet</p>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}
