import React from 'react';

const Kojilive = () => {
    return (
      <>
        <div className='w-11/12 mx-auto'>
          <div className="elfsight-app-e29acaa1-d304-4de0-b9fb-b5324d5af904"></div>
        </div>
      </>
           

        
    );
};

export default Kojilive ;