import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

export default function App() {
  return (
    <>
      <Swiper
        spaceBetween={20}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper mt-20 mx-auto w-11/12 p-auto h-48"
      >
        <SwiperSlide>
          <h1 className="text-white text-center h-24 sm:h-48 mt-6 sm:mt-10 lg:mt-25 font-mono font-black">
            A good laugh and a long sleep are the best cures in the doctor's book.
          </h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1 className="text-white text-center h-24 sm:h-48 mt-6 sm:mt-10 lg:mt-25 font-mono font-black">
            A sad soul can be just as lethal as a germ.
          </h1>
        </SwiperSlide>
        <SwiperSlide>
          <h1 className="text-white text-center h-24 sm:h-48 mt-6 sm:mt-10 lg:mt-25 font-mono font-black">
            A fit body, a calm mind, a house full of love.
          </h1>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
