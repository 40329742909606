import React, { useState } from 'react';

const OrderButton = ({ initialText }) => {
  const [buttonText, setButtonText] = useState(initialText);

  const handleClick = () => {
    setButtonText('Delivered!');
  };

  return (
    <button className='bg-red-500 p-2 rounded text-white' onClick={handleClick}>
      {buttonText}
    </button>
  );
};

export default OrderButton;
